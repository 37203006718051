<template>
  <el-dialog :title="editId ? '编辑分类' : '新增分类'" ref="dialog" :model-value="dialogVisible" width="500px">
    <el-form
      ref="form"
      :model="cateForm"
      status-icon
      :rules="rules"
      v-loading="loading"
      label-width="100px"
      label-position="right"
    >
      <el-form-item label="分类名称" prop="name">
        <el-input clearable v-model="cateForm.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="index">
        <el-input clearable v-model.number="cateForm.index" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, ref, nextTick, getCurrentInstance } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus/lib/components'
import activityModel from '@/model/activity'

export default {
  props: {
    editId: String,
  },
  emits: ['confirm'],
  setup() {
    const dialogVisible = ref(false)
    const form = ref(null)
    const cateForm = reactive({
      name: '',
      index: 0,
      id: '',
    })
    const loading = ref(false)
    const rules = {
      name: [{ message: '请输入分类名称', trigger: 'blur', required: true }],
    }
    const ctx = getCurrentInstance()

    const init = data => {
      dialogVisible.value = true
      nextTick(() => {
        if (data.id) {
          const { name, id, index } = { ...data }
          cateForm.name = name
          cateForm.index = index
          cateForm.id = id
        } else cateForm.id = ''
      })
    }

    const handleClose = () => {
      form.value.resetFields()
      dialogVisible.value = false
    }

    const handleConfirm = () => {
      form.value.validate(async valid => {
        if (valid) {
          console.log(cateForm)
          const api = cateForm.id ? 'editActCate' : 'addActCate'
          let res
          try {
            loading.value = true
            res = await activityModel[api](cateForm)
            ElMessage.success(res.message)
            ctx.emit('confirm')
          } finally {
            form.value.resetFields()
            loading.value = false
            dialogVisible.value = false
          }
        }
      })
    }

    return {
      dialogVisible,
      rules,
      form,
      cateForm,
      loading,

      init,
      handleClose,
      handleConfirm,
    }
  },
}
</script>

<style></style>
